<template>
  <div class="mainboxs">
    <div class="mainbox">
      <div class="news_box">
        <div class="news_til">
          <img style="width: 36px;margin-right: 6px;" src="~@/assets/img/TechnicalCollege/30.png" alt=""> 资讯新闻
        </div>
        <div style="height: 470px;" class="news_concent">
          <div class="news_concents">
            <div @click="goNext(`/gxGather/graduate/newsDetail?id=${item.id}`)" v-for="(item, index) in newsList"
              :key="index">
              <div></div>
              <div class="elp">{{ item.newTitle }}</div>
            </div>
          </div>
          <div @click="goNext(`/gxGather/graduate/news?id=${id}`)" class="news_concents_bot">查看更多资讯新闻<img
              style="margin-left: 8px;width: 12px;height: 12px;" src="~@/assets/img/TechnicalCollege/24.png" alt="">
          </div>
        </div>
      </div>
      <div>
        <div class="news_boxs">
          <div class="bmknow">
            <div class="news_til">
              <div style="display: flex;align-items: center;">
                <img style="width: 36px;margin-right: 6px;" src="~@/assets/img/TechnicalCollege/120.png" alt=""> 报名须知
              </div>
            </div>
            <div class="news_concent">
              <div>
                <div class="con_left">
                  <img style="width: 14px;height: 14px; margin-right: 6px;" src="~@/assets/img/TechnicalCollege/113.png"
                    alt=""> 上课方式
                </div>
                周末班/网络班
              </div>
              <div>
                <div class="con_left">
                  <img style="width: 14px;height: 14px; margin-right: 6px;" src="~@/assets/img/TechnicalCollege/109.png"
                    alt=""> 学习期限
                </div>
                1.5-2年
              </div>
              <div>
                <div class="con_left">
                  <img style="width: 14px;height: 14px; margin-right: 6px;" src="~@/assets/img/TechnicalCollege/110.png"
                    alt=""> 获得证书
                </div>
                学位证 结业证
              </div>
              <div>
                <div class="con_left">
                  <img style="width: 14px;height: 14px; margin-right: 6px;" src="~@/assets/img/TechnicalCollege/111.png"
                    alt=""> 学习形式
                </div>
                非全日制
              </div>
              <div>
                <div class="con_left">
                  <img style="width: 14px;height: 14px; margin-right: 6px;" src="~@/assets/img/TechnicalCollege/112.png"
                    alt=""> 学费情况
                </div>
                <span>12~20万元/年</span>
              </div>
            </div>
          </div>
          <div class="questionlist">
            <div class="news_til">
              <div style="display: flex;align-items: center;">
                <img style="width: 36px;margin-right: 6px;" src="~@/assets/img/TechnicalCollege/45.png" alt=""> 常见问题
              </div>
              <div @click="goNext('/gxGather/graduate/question')" class="news_til_right">更多 <img
                  style="width: 12px;margin-left: 8px;" src="~@/assets/img/TechnicalCollege/48.png" alt=""></div>
            </div>
            <div class="news_concent">
              <div class="news_concents">
                <div v-for="(item, index) in qusetionList" :key="index">
                  <div></div>
                  <div class="elp">{{ item.questionName }}</div>
                </div>

              </div>
            </div>
          </div>


        </div>
        <div class="apply_box">
          <div class="apply_box_til"> <img style="width: 36px;" src="~@/assets/img/TechnicalCollege/23.png" alt=""> 报名咨询
          </div>
          <div class="form">
            <el-form class="centent_form" :rules="rules" ref="form" :model="form" label-position="left">
              <el-form-item label="学生姓名： " prop="name">
                <el-input v-model="form.name" placeholder="您的称呼"></el-input>
              </el-form-item>
              <el-form-item label="手机号码：" prop="phone">
                <el-input v-model="form.phone" placeholder="手机号码"></el-input>
              </el-form-item>
              <el-form-item label="验证码：" prop="code">
                <el-input v-model="form.code" placeholder="验证码">
                  <span class="yCode" slot="suffix" v-if="!downTime" @click="codeClick">获取验证码</span>
                  <el-statistic v-else slot="suffix" :value="countDown" time-indices format="ss" @finish="timeFinish">
                    <template slot="suffix">s</template>
                  </el-statistic>
                </el-input>
              </el-form-item>
              <el-form-item prop="majorId" label="专业：">
                <el-select @change="schoolChange()" v-model="form.majorId" placeholder="请选择">
                  <el-option v-for="(item, index) in countryList" :key="index" :label="item.majorName"
                    :value="item.majorId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item @change="$forceUpdate()" prop="schoolId" label="学校：">
                <el-select v-model="form.schoolId" placeholder="请选择">
                  <el-option v-for="(item, index) in schoolList" :key="index" :label="item.value" :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="education" label="学历：">
                <el-select v-model="form.education" placeholder="请选择">
                  <el-option label="专科" value="专科" />
                  <el-option label="本科无学位" value="本科无学位" />
                  <el-option label="本科有学位" value="本科有学位" />
                  <el-option label="硕士" value="硕士" />
                  <el-option label="博士" value="博士" />

                </el-select>
              </el-form-item>
              <el-form-item class="query" label="报名疑问：" prop="query">
                <el-input v-model="form.query" placeholder="疑问"></el-input>
              </el-form-item>
            </el-form>
            <div @click="submitClick" class="apply_box_btn">提交</div>
            <div class="apply_box_des">提交咨询后，我们会24小时内电话联系您</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
import { loginSmsCode } from "@/api/login";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
  components: {},

  data() {
    return {
      countDown: Date.now() + 1000 * 60,
      downTime: false,

      search: {
        pageSize: 14,
        pageNum: 1,
        projectTypeId: this.id
      },
      form: {},
      rules: {
        name: [{ required: true, message: " ", trigger: "blur" }],
        examStage: [{ required: true, message: " ", trigger: "blur" }],
        phone: [
          { required: true, message: " ", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: " ",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: " ", trigger: "blur" }],
        majorId: [{ required: true, message: " ", trigger: "change" }],
        schoolId: [{ required: true, message: " ", trigger: "change" }],
        education: [{ required: true, message: " ", trigger: "change" }],

      },
      newsList: [],
      qusetionList: [],
      schoolList: [],
      countryList: [],
      projectType: '',
    };
  },
  created() {
    this.projectType = localStorage.getItem('projectType');
    this.search.projectTypeId = localStorage.getItem('projectTypeId');
    this.search.projectCategoryId = localStorage.getItem('projectCategoryId');
    know.getContentNewPcList({ ...this.search }).then((res) => {
      this.newsList = res.rows
    });
    know.getSchoolProblemPcList({
      pageSize: 7,
      pageNum: 1,
      projectTypeId: this.search.projectTypeId
    }).then((res) => {
      this.qusetionList = res.rows
    });
    know.getBriefsTuitionPcList({ pageSize: 999, pageNum: 1, projectType: this.projectType }).then((res) => {
      this.countryList = res.data
    });
    know.getSchoolList({ pageSize: 999, pageNum: 1, majorId: this.form.majorId ? this.form.majorId : undefined, projectType: this.projectType }).then((res) => {
      this.schoolList = res.data
    });

  },
  mounted() {

  },
  methods: {
    schoolChange() {
      this.form.schoolId = ''
      know.getSchoolList({ pageSize: 999, pageNum: 1, majorId: this.form.majorId ? this.form.majorId : undefined, projectType: this.projectType }).then((res) => {
        this.schoolList = res.data
      });
    },
    // 获取验证码
    async codeClick() {
      this.countDown = Date.now() + 1000 * 60;
      if (!this.form.phone) {
        this.$message.error("请先输入手机号");
        return;
      } else {
        const res = await loginSmsCode(this.form.phone, "welcomePotential");
        if (res.code === 0) {
          this.$message.success(res.msg);
          this.downTime = true;
        } else if (res.code === 500) {
          this.$message.error("请勿频繁获取！");
        }
      }
    },
    // 倒计时结束
    timeFinish(val) {
      if (val) return (this.downTime = false);
    },
    // 立即提交
    submitClick() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const foundItem = this.countryList.find(item => item.majorId === this.form.majorId);
          if (foundItem) {
            this.form.intentionMajor = foundItem.majorName;
          } else {
            this.form.intentionMajor = ''
          }

          const foundItems = this.schoolList.find(item => item.key == this.form.schoolId);
          if (foundItems) {
            this.form.intentionSchool = foundItems.value;
          } else {
            this.form.intentionSchool = ''
          }
          const res = await know.addGraduateUserConsultation({
            studentName: this.form.name,
            mobile: this.form.phone,
            mobileToken: this.form.code,
            majorId: this.form.majorId,
            schoolId: this.form.schoolId,
            query: this.form.query,

            intentionMajor: this.form.intentionMajor,
            intentionSchool: this.form.intentionSchool,
            education: this.form.education,
            consultationClassify: localStorage.getItem("consultationClassify"),
            projectType: "1",
          });
          if (res.code === 0) {
            this.$message.success(res.msg);
            this.form = {}
            this.downTime = false
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error("请将信息填写完整 ！");
        }
      });
    },

  },
};
</script>

<style lang="less" scoped>
.mainboxs {
  //background: #FFFFFF;
}

.mainbox {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;

  .news_box {
    width: 370px;
    height: 575px;
    background: #FFFFFF;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #EEEEEE;
    padding: 20px;

    .news_til {
      display: flex;
      align-items: center;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
      font-weight: normal;
      font-size: 20px;
      color: #333333;
      font-weight: bold;
      flex-shrink: 0;

      .news_til_left_eng {
        margin-left: 5px;
        width: 100%;
        // height: 26px;
        line-height: 24px;
        font-family: Helvetica, Helvetica;
        font-weight: normal;
        font-size: 13px;
        color: rgba(54, 72, 122, 0.5);
        border-bottom: 1px solid rgba(154, 163, 188, 0.5);
      }


    }

    .news_concent {
      width: 300px;
      height: 420px;
      border-radius: 4px 4px 4px 4px;
      margin-top: 13px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .news_concents {
        padding: 15px 0;

        >div {
          display: flex;
          align-items: center;

          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 14px;
          color: #444444;
          margin-bottom: 10px;
          cursor: pointer;

          >div:nth-child(1) {
            width: 3px;
            height: 3px;
            background: #E1E1E1;
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;
          }

          >div:nth-child(2) {
            width: 300px;
          }
        }

        >div:hover {
          color: #BD1212;
        }
      }

      .news_concents_bot {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC-Regular, PingFangSC-Regular;
        font-weight: normal;
        font-size: 14px;
        color: #1061FF;
        cursor: pointer;
        width: 244px;
        height: 30px;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #3D6CC8;
        margin: 0 auto;

        &:hover {
          background: rgba(61, 108, 200, 0.1);
        }
      }
    }
  }

  .news_boxs {
    width: 810px;
    // height: 258px;
    display: flex;
    justify-content: space-between;

    >div {
      width: 400px;
      height: 284px;
      background: #FFFFFF;
      border-radius: 3px 3px 3px 3px;
      padding: 20px;

      .news_til {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: normal;
        font-size: 20px;
        color: #333333;
        font-weight: bold;
        flex-shrink: 0;

        .news_til_right {

          display: flex;
          align-items: center;
          font-family: PingFangSC-Regular, PingFangSC-Regular;
          font-weight: normal;
          font-size: 14px;
          color: #777777;
          cursor: pointer;
        }
      }

    }



    .questionlist {
      .news_concent {
        width: 330px;
        height: 200px;
        border-radius: 4px 4px 4px 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .news_concents {
          padding: 15px;

          >div {
            display: flex;
            align-items: center;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 14px;
            color: #444444;
            margin-bottom: 10px;
            cursor: pointer;

            >div:nth-child(1) {
              width: 3px;
              height: 3px;
              background: #E1E1E1;
              border-radius: 50%;
              margin-right: 10px;
              flex-shrink: 0;
            }

            >div:nth-child(2) {
              width: 300px;
            }
          }

          >div:hover {
            color: #BD1212;
          }
        }

        .news_concents_bot {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFangSC-Regular, PingFangSC-Regular;
          font-weight: normal;
          font-size: 14px;
          color: #1061FF;
          cursor: pointer;
        }
      }
    }

    .bmknow {
      .news_concent {
        padding-left: 10px;

        >div {
          display: flex;
          align-items: center;
          font-family: PingFangSC-Medium, PingFangSC-Medium;
          font-weight: normal;
          font-size: 14px;
          color: #444444;
          margin-top: 15px;

          .con_left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 88px;
            height: 26px;
            border-radius: 6px;
            margin-right: 10px;
            border: 1px solid #3D4C6E;
          }

          span {

            font-family: PingFangSC-Medium, PingFangSC-Medium;
            font-weight: normal;
            font-size: 16px;
            color: #DD4C4C;
          }
        }
      }
    }

  }


  .apply_box {
    width: 810px;
    height: 270px;
    border-radius: 3px 3px 3px 3px;
    background: linear-gradient(180deg, #FFFFFF 0%, #DBDFE3 100%);
    background-image: url('~@/assets/img/TechnicalCollege/95.png');
    background-size: 100% 100%;
    padding: 20px;
    margin-top: 20px;

    .apply_box_til {
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
      font-weight: normal;
      font-size: 20px;
      color: #1061FF;
      display: flex;
      align-items: center;
      font-weight: bold;

    }

    .form {
      // padding: 0 20px;
      // margin: 38px 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;

      input {
        width: 150px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DDDDDD;
        padding: 0 10px;
      }

      input:nth-child(3),
      input:nth-child(4) {
        margin-top: 20px;
      }

      select {
        width: 150px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DDDDDD;
        padding: 0 10px;
        margin-top: 20px;

      }


    }

    .apply_box_btn {
      width: 180px;
      height: 40px;
      background: #1061FF;
      border-radius: 20px 20px 20px 20px;

      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: normal;
      font-size: 14px;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      cursor: pointer;
    }

    .apply_box_des {
      width: 100%;

      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: normal;
      font-size: 12px;
      color: #999999;
      text-align: right;
    }
  }
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

.centent_form {
  // margin-top: 57px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  /deep/ .el-form-item:nth-last-child(1) {
    margin-right: 0;
  }

  /deep/ .el-input__suffix {
    right: 16px !important;
  }

  /deep/ .el-form-item {
    display: flex;
    justify-content: space-between;
    // margin-right: 10px;
    margin-bottom: 5px;
    margin-right: 10px;

    .el-form-item__label {
      width: 72px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: normal;
      font-size: 14px;
      color: #666666;
      padding-right: 0;
    }

    .el-form-item__label::before {
      content: "";
      margin-right: 0;
    }

    .el-form-item__content {
      width: 150px;
      color: #848484 !important;

      // margin-left: 0px !important;
      .el-input {
        width: 100%;
        color: #848484 !important;

        .el-input__inner {
          width: 150px;
          height: 34px;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #DDDDDD;
          padding: 0 10px;
        }

        .yCode {

          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 12px;
          color: #1061FF;
          cursor: pointer;
        }

        .el-statistic {
          margin: 13px 5px 0 0;
        }

        .number {
          font-size: 12px;
          font-family: PingFangSC-Regular-, PingFangSC-Regular;
          font-weight: normal;
          color: #333333;
          letter-spacing: 1px;
        }
      }

      ::placeholder {
        color: #848484 !important;
      }

      // height: 30px;
      // border-radius: 30px;
    }
  }
}

/deep/ .query {
  width: 695px;
  justify-content: flex-start !important;


  .el-form-item__content {
    width: 613px !important;
    color: #848484 !important;

    // margin-left: 0px !important;
    .el-input {
      width: 613px !important;
      color: #848484 !important;

      .el-input__inner {
        width: 613px !important;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DDDDDD;
        padding: 0 10px;
      }
    }
  }
}
</style>
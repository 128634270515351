<template>
    <div class="certificateView">
        <div style="width: 100%">
            <img src="~@/assets/img/TechnicalCollege/115.png" alt="" />
        </div>
        <div style="width: 100%; ">
            <news class="banner" />
        </div>
        <div style="padding-bottom: 20px;">
            <academy></academy>
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
// import banners from "./banner.vue";
import news from "./news.vue";
// import major from "./major.vue";
import academy from "./academy.vue";

export default {
    components: { news, academy },

    data() {
        return {
            id: "",
            countryList: [],
            mList: [],
            whichColor: 1
        };
    },
    created() {
        this.id = localStorage.getItem("projectTypeId");
    },
    mounted() { },
    methods: {
        onhover(i) {
            this.whichColor = i
        }
    },
};
</script>

<style lang="less" scoped>
.itembox {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;

    .til {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: normal;
        font-size: 40px;
        color: #444444;
        font-weight: bold;

        img {
            width: 122px;
            height: 54px;
        }

        >div {
            margin: 0 28px;
        }
    }

    .item_con {
        display: flex;
        margin-top: 50px;
        justify-content: space-between;

        >div {
            width: 364px;
            height: 436px;
            background: url("~@/assets/img/TechnicalCollege/75.png");
            background-size: 100% 100%;
            // border-radius: 100px 6px 6px 6px;
        }

        >div:nth-child(2) {
            background: url("~@/assets/img/TechnicalCollege/76.png");
            background-size: 100% 100%;
        }

        >div:nth-child(3) {
            background: url("~@/assets/img/TechnicalCollege/77.png");
            background-size: 100% 100%;
        }

        .item_con_til {
            position: relative;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 24px;
            color: #333333;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 22px;

            .line {
                position: absolute;
                bottom: -5px;
                width: 192px;
                height: 17px;
                background: linear-gradient(90deg, #705ad5 0%, #ffc4af 100%);
                border-radius: 0px 0px 0px 0px;
            }
        }

        .item_con_text {
            width: 300px;
            height: 210px;
            font-family: PingFangSC-Regular, PingFangSC-Regular;
            font-weight: normal;
            font-size: 20px;
            color: #666666;
            line-height: 30px;
            margin: 0 auto;
            margin-top: 24px;
        }
    }

    .itembox_con {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div {
            width: 220px;
            height: 220px;
            background: #ffffff;
            border: 2px solid #164694;
            border-radius: 50%;
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .itembox_con_til {
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 20px;
                color: #333333;
                text-align: center;
                margin-top: 40px;
            }

            .line {
                width: 26px;
                height: 3px;
                background: #164694;
                border-radius: 0px 0px 0px 0px;
                margin: 8px auto;
            }

            .itembox_con_text {
                width: 180px;
                height: 90px;
                font-family: PingFangSC-Regular, PingFangSC-Regular;
                font-weight: normal;
                font-size: 13px;
                color: #999999;
                text-align: center;
                font-style: normal;
                text-transform: none;
            }

            &:nth-child(2) {
                border: 2px solid #3773c7;
            }

            &:nth-child(3) {
                border: 2px solid #89ba3f;
            }

            &:nth-child(4) {
                border: 2px solid #f3c055;
            }

            &:nth-child(5) {
                border: 2px solid #ed706b;
            }
        }
    }

    .itembox_connect {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        margin-top: 50px;

        >div {
            width: 180px;

            font-family: PingFangSC-Regular, PingFangSC-Regular;
            font-weight: normal;
            font-size: 16px;
            color: #333333;
            line-height: 26px;
            background: #ffffff;
            border-radius: 6px 6px 6px 6px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            >div:nth-child(2) {
                margin-top: 20px;
            }

            img {
                width: 110px;
                // height: 40px;
            }

            &:hover {
                color: #FFFFFF;
            }
        }

        .i_con1 {
            width: 180px;
            height: 210px;

            &:hover {
                background: #164694;
            }
        }

        .i_con2 {
            width: 180px;
            height: 230px;

            &:hover {
                background: #3773C7;
            }
        }

        .i_con3 {
            width: 180px;
            height: 260px;

            &:hover {
                background: #89BA3F;
            }
        }

        .i_con4 {
            height: 290px;

            &:hover {
                background: #F3C055;
            }
        }

        .i_con5 {
            height: 320px;

            &:hover {
                background: #ED706B;
            }
        }

        .i_con6 {
            height: 350px;

            &:hover {
                background: #C971EE;
            }
        }

        .line {
            position: absolute;
            width: 1042px;
            height: 338px;
            background: transparent;
            z-index: 99;
            left: 65px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.bgg {
    background: url("~@/assets/img/TechnicalCollege/78.png");
    background-size: 100% 100%;
}

img {
    width: 100%;
}
</style>